.downloadApp {
  color: black;
  text-align: center;
  padding: 18px;
  overflow: unset;
}

.title {
  font-size: 32px;
}

.description {
  font-size: 14px;
  color: var(--IRON);
  max-width: 220px;
  margin: 14px auto 0;
}

.downloadLinks {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 16px;
}
.downloadLinks img {
  width: calc(50% - 2.5px);
  max-width: 150px;
  margin: 0 2px;
  height: auto;
  align-self: flex-start;
  cursor: pointer;
}