.root {
  display: flex;
  z-index: 3;
}

.carousel {
  margin-left: 0px !important;
  width: 100vw;
  margin-top: calc(var(--COLUMN) * 1);
}

.slideCard {
  display: block;
  background: white;
  margin: 0;
  height: 100%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

.cardImage {
  height: 35vh;
  background-color: #eee;
  background-size: cover;
  background-position: center;
}

.background {
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.cardTitle {
  margin: 32px;
  margin-bottom: 10px;
  color: var(--CHAR);
}

.cardBody {
  color: var(--IRON);
  margin: 32px;
  margin-top: 10px;
  font-size: 16px;
}

.demoLinks {
  display: flex;
  position: relative;
}
.demoLinks div {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.demoLinks div a {
  color: var(--ROSE);
  text-decoration: none;
  margin-bottom: 10px;
}