:global(.nav) > div {
  margin-top: 24px !important;
}

.web {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: min(400px, 100vw);
  min-width: 100px;
  text-align: center;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
}

.download {
  margin: 24px var(--GUTTER);
  padding-bottom: 48px;
}

.logos {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: calc(24px + var(--sab) - var(--sat));
}
.logos .logoContainer {
  flex-shrink: 0;
  flex-grow: 1;
  width: calc(50% - 1px);
  display: flex;
  justify-content: center;
}

.divider {
  width: 2px;
  height: 50px;
  flex-shrink: 0;
  background: white;
}

.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  background-size: cover;
  background-position: center bottom;
  z-index: -1;
}

.hiddenButton {
  height: 40px;
  width: 80px;
  margin-right: -40px;
  position: relative;
  display: block;
}