.root {
  z-index: 2;
}

.background {
  position: absolute;
  top: -200px;
  width: 100vw;
  left: 0;
  z-index: -1;
}

:global(.h3), :global(.h1), :global(.p), :global(.h2), button {
  transition: opacity 0.4s;
  opacity: 1;
}

.hideText :global(.h3), .hideText :global(.h1), .hideText :global(.h2), .hideText :global(.p), .hideText button {
  opacity: 0;
}

.topSection {
  text-align: center;
}

.title {
  margin-top: 14vh;
  color: var(--WINE);
}

.subtitle {
  margin-top: 24vh;
}

.content {
  color: white;
  margin-top: 10vh;
  margin-left: calc(var(--GUTTER) * -1);
  width: calc(100vw - var(--GUTTER) * 2);
  padding: var(--GUTTER);
  background: linear-gradient(rgba(215, 154, 52, 0), #d79a34 20%);
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item .icon {
  margin-top: 46px;
}
.item .body {
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
  line-height: 133%;
  max-width: 290px;
}
.item .button {
  margin-bottom: 50px;
}

.version {
  text-align: center;
  font-size: 14px;
  margin: 57px 0;
}

.beyond {
  text-align: center;
}

.backgroundContainer {
  position: absolute;
  top: 0;
  left: calc(-1 * var(--GUTTER));
  width: 100vw;
  z-index: -1;
}