.root {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 47px;
}

.rootContainer {
  z-index: 2;
  background: white;
}

.graphic {
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
}

.grid {
  position: absolute;
  left: calc(var(--GUTTER) * -1);
  z-index: -1;
}
.grid svg {
  width: 100vw;
  height: auto;
}

.title {
  text-align: center;
}

.paragraph {
  margin-top: 16px;
  text-align: center;
  color: var(--IRON);
}

.button {
  margin-top: 47px;
}