.container {
  margin-left: calc(var(--GUTTER) * -1);
}
.container::-webkit-scrollbar {
  display: none;
}

.slides {
  position: relative;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  padding: 24px 0;
  margin-top: -24px;
}
.slides::-webkit-scrollbar {
  display: none;
}

.slide {
  scroll-snap-align: center;
  flex-shrink: 0;
  width: calc(100vw - 72px);
  margin-left: 24px;
}
.slide:last-child {
  padding-right: 48px;
}

.selector {
  position: relative;
  height: 10px;
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.selectorDot {
  width: 7px;
  height: 7px;
  margin: 0 4px;
  border-radius: 7px;
}