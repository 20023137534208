.root {
  position: relative;
}

.tabs {
  display: flex;
  margin-left: calc(var(--GUTTER) * -1);
  width: calc(100vw - var(--GUTTER) * 2);
  padding: 0 var(--GUTTER);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 24px;
  justify-content: space-around;
}
.tabs .tab {
  position: relative;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding: 10px 16px 0px;
}
.tabs.horizontalScroll {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  justify-content: flex-start;
}
.tabs.horizontalScroll::-webkit-scrollbar {
  display: none;
}
.tabs.horizontalScroll .tab {
  width: auto;
}

.active {
  font-weight: bold;
}

.underline {
  margin-top: 18px;
  height: 2px;
  background: var(--text-color);
  width: 100%;
}

.content {
  position: absolute;
}