@import "styles/fonts";

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom, 0px);
  --sal: env(safe-area-inset-left);
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  min-height: 100vh;
  background-size: 480vw auto;
  background-position: -46vh -61vw;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color .8s, color .8s;
  -webkit-font-smoothing: antialiased;
  font-smooth: subpixel-antialiased;
  overflow-x: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.root {

}
