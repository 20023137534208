.root {
  background: var(--MINT);
  overflow-y: scroll;
}

.content {
  margin-top: 36px;
  text-align: center;
}

.body {
  font-size: 16px;
  margin: 24px 0;
}

.card {
  background: white;
  display: flex;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 24px;
  align-items: center;
  color: var(--CHAR);
}

.cardImg {
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  background: #eee;
  margin-right: 14px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}