.root {
  background: var(--bg-color);
  transition: background-color 0.8s, opacity 0.4s;
  z-index: 3;
}

.hideText {
  opacity: 0;
}

.title {
  text-align: center;
  margin-top: 3vh;
}
@media all and (max-height: 700px) {
  .title {
    margin-top: 0px;
  }
}

.designGuideBody {
  font-size: 16px;
  text-align: center;
  margin: 20px auto 47px;
  line-height: 133%;
}

.app, .root {
  height: 100%;
}

.logo {
  text-align: center;
  margin: 24px auto 24px;
}
@media all and (max-height: 700px) {
  .logo {
    margin-top: -20px;
  }
}

@media all and (max-height: 700px) {
  .selectorCards {
    margin-top: -20px;
  }
}

.selectorCard {
  margin: 22px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18vh;
  min-height: 115px;
}

.card {
  margin-bottom: 24px;
  color: var(--CHAR);
  position: relative;
}

.cardTopClosed {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 28px 28px 0;
}

.cardTitle {
  line-height: 100%;
}

.cardImage {
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  background: #eee;
  margin-right: 14px;
  object-fit: cover;
}

.imageSubstitute {
  height: 28px;
}

.cardTopOpen {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.cardTopOpen .cardImage {
  width: 100%;
  margin-right: 0;
  margin-bottom: 24px;
}
.cardTopOpen .cardTitle {
  line-height: 100%;
  margin: 0 28px;
}

.cardContent {
  font-size: 16px;
  color: var(--IRON);
  padding: 0 28px 48px;
}

.cardContentBody {
  position: relative;
  overflow: hidden;
}
.cardContentBody.cardContentHidden:after {
  position: absolute;
  content: "";
  bottom: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  height: 60px;
  width: 100%;
}
.cardContentBody.cardContentVisible:after {
  position: absolute;
  content: "";
  bottom: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  height: 0px;
  width: 100%;
}

.beyondTheDistrict {
  text-align: center;
}

.partners {
  margin-bottom: 140px;
}

.cardAttrKey {
  color: #111;
  font-size: 16px;
  padding-top: 16px;
}

.cardAttrValue {
  font-size: 16px;
  color: var(--IRON);
}

.chevron {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  padding: 15px 0;
  border-radius: 0;
}