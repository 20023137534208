.root {
  display: flex;
  flex-direction: column;
}

.landing {
  height: 100%;
}

:global(.h3), :global(.h1), :global(.p), button {
  transition: opacity 0.4s;
  opacity: 1;
}

.hideText :global(.h3), .hideText :global(.h1), .hideText :global(.p), .hideText button {
  opacity: 0;
}

.title, .paragraph {
  text-align: center;
}

.title {
  margin-top: 100px;
}
@media all and (max-height: 700px) {
  .title {
    margin-top: 30px;
  }
  .title :global(.h1) {
    font-size: 38px;
  }
}

.paragraph {
  margin-top: 5px;
  font-size: 16px;
}

.button {
  margin: 15px auto;
  color: var(--text-color);
  border-color: rgba(128, 0, 83, 0.3) !important;
}

.buttons {
  position: fixed;
  height: 100vw;
  max-height: 50vh;
  max-width: 375px;
  bottom: 0;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 350px) {
  .buttons :global(.h3) {
    font-size: 38px;
  }
}

.viewButton, .mapButton, .buildYourOwnButton {
  position: fixed;
  transition: opacity 0.1s;
}
.viewButton:active, .mapButton:active, .buildYourOwnButton:active {
  opacity: 0.7;
}

.viewButton {
  right: -6%;
  bottom: 31%;
  width: 55%;
}
.viewButton svg {
  width: 100%;
}

.viewButtonText {
  position: absolute;
  top: 35%;
  right: 30%;
}

.mapButton {
  left: -5%;
  bottom: 42%;
  width: 60%;
}
.mapButton svg {
  width: 100%;
}

.mapButtonText {
  color: white;
  position: absolute;
  left: 35%;
  top: 35%;
}

.buildYourOwnButton {
  bottom: -10px;
  left: 0;
  width: 100%;
  min-width: 330px;
}
.buildYourOwnButton svg {
  width: 100%;
  height: auto;
}

.buildYourOwnButtonText {
  color: white;
  position: absolute;
  left: 15%;
  bottom: 14%;
  z-index: 2;
  pointer-events: none;
}