.root {
  z-index: 5;
  background: var(--SWAN);
}

.title {
  text-align: center;
  margin-top: 8vh;
  text-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  line-height: 100%;
}
@media all and (max-height: 700px) {
  .title {
    margin-top: 0px;
  }
}
.title.titleLuminaries {
  margin-top: 33vh;
}
@media all and (max-height: 700px) {
  .title.titleLuminaries {
    margin-top: 28vh;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.wrap::-webkit-scrollbar {
  display: none;
}

:global(.h3), :global(.h1), :global(.p), :global(.h2), button {
  transition: opacity 0.4s;
  opacity: 1;
}

.hideText :global(.h3), .hideText :global(.h1), .hideText :global(.h2), .hideText :global(.p), .hideText button {
  opacity: 0;
}

.content {
  color: black;
  font-size: 16px;
  margin-top: 8vh;
  margin-bottom: 16px;
}
@media all and (max-height: 700px) {
  .content {
    margin-top: 20px;
  }
}

.luminariesContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  margin-top: 24px;
  margin-bottom: 16px;
}

.viewInAr {
  margin-top: 15px;
  width: 100%;
}

.location {
  font-weight: 500;
  line-height: 130%;
  max-width: 200px;
}

.details {
  margin-top: 4px;
  font-size: 14px;
  line-height: 150%;
  color: var(--IRON);
}

.unlockTag {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: -12px;
}

.background {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.overlayFade, .overlay {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 0;
}

.overlay {
  background: rgba(0, 0, 0, 0.05);
}

.bgImageTest {
  width: 100vw;
  height: auto;
  z-index: -1;
  position: relative;
}
.bgImageTest:after {
  content: "";
  position: absolute;
  width: 100vw;
  height: 40px;
  background: goldenrod;
  bottom: 0;
}

.bgGradient {
  width: 100vw;
  height: 80px;
  background: goldenrod;
  margin-top: -40px;
  z-index: 0;
  background: linear-gradient(rgba(223, 223, 223, 0) 0%, #DFDFDF 50%);
}

.bgGradientContinued {
  background: #DFDFDF;
  width: 100vw;
  height: -webkit-fill-available;
}

.bgImage {
  background-size: contain;
  background-position: top center;
  position: absolute;
  background-repeat: no-repeat;
  height: auto;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
}

.unlockedShapes {
  color: black;
}

.shapes {
  font-size: 14px;
  font-weight: 500;
}

.unlockedShapesDescription {
  font-size: 14px;
  line-height: 140%;
  color: var(--IRON);
}

.unlockedShapesShapes {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  width: calc(100% + 56px);
  margin-left: -28px;
}
.unlockedShapesShapes::-webkit-scrollbar {
  display: none;
}
.unlockedShapesShapes > div {
  flex-shrink: 0;
  width: 68px;
  height: 68px;
  margin-right: 12px;
  background-color: #f5f5f5;
  border-radius: 5px;
  scroll-snap-align: center;
  position: relative;
}
.unlockedShapesShapes > div:first-child {
  margin-left: 28px;
}
.unlockedShapesShapes > div:last-child {
  margin-right: 28px;
}
.unlockedShapesShapes > div img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.buildYourOwn {
  width: 100%;
}