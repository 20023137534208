.rootContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
}

.navPadding {
  flex-shrink: 0;
  height: 72px;
  padding-top: calc(var(--sat, 44px) + 14px);
}

.root {
  height: 100vh;
  margin: 0 var(--GUTTER);
  position: relative;
  display: flex;
  flex-direction: column;
}

.bgImage {
  height: 100vh;
  width: 100vw;
  background-size: cover;
}